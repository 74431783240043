import React from 'react'
import { config, useSpring } from 'react-spring'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import Img from 'gatsby-image'

const About = ({ data }) => {

  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (  
    <Layout>
      <SEO title="About | Charlie Trotter Photography" desc="Hi. I'm Charlie Trotter You can visit my website or my portfolio of projects." />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>Who is Charlie Trotter?</h1>
        <p>Charlie Trotter chooses one thing above all — happiness. Not only can design and photography represent who you are as a person, but it can be preserved forever. Charlie started designing in 2005 while Photography/Videography started for him in 2011.  He quickly fell in love with the idea of creating and decided to invest in the business. Energized by passion, his desire to grow his creative skillset led him to starting Charlie Trotter Photography. Let's connect!</p>
        <br />
        <hr />
        <p>
          <Img fluid={data.gordonparksImage.childImageSharp.fluid} />
        </p>
      </AnimatedBox>
    </Layout>
  )
}

export default About

export const query = graphql`
query {
  gordonparksImage: file(sourceInstanceName: { eq: "images" }, name: { eq: "GordonParks" }) {
    childImageSharp {
      fluid(quality: 95, maxWidth: 1200) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

